import React from "react";
import { Link } from "gatsby";
import Layout from "../../../components/layout";
import { RecrutationForm } from "../../../components";

const StrategyPlanner = ({ location: { pathname }, pageContext }) => {
  const { data: title } = pageContext;
  return (
    <Layout
      seo={{
        title: "Strategy Planner",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/kariera/strategy-planner/",
      }}
    >
      <section className="page_recrutation">
        <h1>Carerry</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item active">
                <Link to="/en/career/strategy-planner">Strategy Planner</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>
                We are looking for experienced strategists, who will be
                responsible for creating and implementing long-term marketing
                strategies for our clients. We are a group of companies from the
                advertising and marketing industry - each of them with its own
                narrow specialization, but thanks to joint action, we offer
                clients a wide range of services. We are looking for people with
                whom we will be able to work even wider, confront different
                directions of thinking about marketing, and derive new industry
                know-how.
              </p>
              <h2>Responsibilities:</h2>
              <p>
                Your job will be to introduce new inspirations, knowledge and
                unique know-how to our strategies. We do not want to limit you
                to specific strategic areas - tell us what you are good at: in
                consumer research, communication strategies, branding, 360
                campaigns, digital marketing strategies. We will require
                proficiency in monitoring the market, trends, tools, competition
                and research as well as analyzing the effectiveness of our
                activities. We need people who will recommend even better
                development paths for our clients.
              </p>
              <h2>Requirements:</h2>
              <ul>
                <li>
                  several years of experience in creating marketing strategies;
                </li>
                <li>excellent knowledge of traditional and digital media;</li>
                <li>
                  efficient combination of creativity and analytical thinking;
                </li>
                <li>good manners;</li>
                <li>excellent communication skills;;</li>
                <li>full professional proficiency in English</li>
                <li>
                  knowledge of issues related to market research, marketing and
                  business analysis;
                </li>
                <li>basic knowledge of psychology, sociology and economics</li>
              </ul>
              <h2>We can offer you:</h2>
              <ul>
                <li>
                  work in the project’s system (maybe also full-time - we'll
                  talk about it);
                </li>
                <li>the ability to create strategies for large companies;</li>
                <li>
                  cooperation with specialists from our companies, including
                  website development, internet marketing, visual identification
                  and branding;
                </li>
                <li>
                  partners for whom you will be able to implement B2B projects;
                </li>
                <li>
                  salary will be determined individually on the basis of your
                  experience and the agreed scope of cooperation.
                </li>
              </ul>
              <h2>
                Meet us! We will show you how we have worked so far, and you
                will show us the gems among your strategies. We would like to
                know:
              </h2>
              <ul>
                <li>
                  How many years of experience do you have in the industry?
                </li>
                <li>How many strategies have you developed?</li>
                <li>
                  What was the scope of the strategies? (How extensive were
                  they?)
                </li>
                <li>
                  What brands did you prepare your strategies for and what goals
                  did you achieve? (Tell us about the 3 most recognizable
                  companies.
                </li>
              </ul>
              <RecrutationForm position={title || ""} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default StrategyPlanner;
